// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-3-js": () => import("./../../../src/pages/lp-3.js" /* webpackChunkName: "component---src-pages-lp-3-js" */),
  "component---src-pages-lp-4-js": () => import("./../../../src/pages/lp-4.js" /* webpackChunkName: "component---src-pages-lp-4-js" */),
  "component---src-pages-lp-5-js": () => import("./../../../src/pages/lp-5.js" /* webpackChunkName: "component---src-pages-lp-5-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */)
}

